import React, { useEffect, useState } from "react";
import style from "./AlertContainer.module.scss";
import Alert from "./Alert";
import { IAlert, alertsMock } from "src/state/store.model";
import { useStore } from "src/state/Store";

const TIME_INTERVAL = 500;
const backGroundColor = process.env.REACT_APP_ALERT_BACKGROUND_COLOR;

const AlertContainer = () => {
  // const [alerts, setAlerts] = useState<IAlert[]>(alertsMock);
  // const [index, setIndex] = useState(0);

  const store = useStore();

  // useEffect(() => {
  //   setAlerts(store.alerts);
  // }, [store]);

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     if (index < alertsMock.length) {
  //       setAlerts((prev) => [...prev, alertsMock[index]]);
  //       setIndex((prevIndex) => prevIndex + 1);
  //     } else {
  //       clearTimeout(interval);
  //     }
  //   }, TIME_INTERVAL);

  //   return () => clearInterval(interval);
  // }, [index]);

  return (
    <div
      style={{ backgroundColor: backGroundColor }}
      className={style.container}
    >
      {store.alerts?.map((item, index) => (
        <Alert key={item.id} {...item} />
      ))}
    </div>
  );
};

export default AlertContainer;
