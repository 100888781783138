import style from "./Alert.module.scss";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IAlert } from "src/state/store.model";
import { useStore } from "src/state/Store";

const VISIBLE_DURATION = 10000;

const Alert = (props: IAlert) => {
  const [show, changeShow] = useState(true);

  const store = useStore();

  const removeAlert = (id: string) => {
    store.removeAlert(id);
    changeShow(false);
  };

  const handleClick = () => {
    removeAlert(props.id);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      removeAlert(props.id);
    }, VISIBLE_DURATION);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {show && (
        <div className={style.container}>
          <div className={style.content}>
            <p>{props.message}</p>
          </div>
          <button className={style.button} onClick={handleClick}>
            <CloseIcon sx={{ fontSize: 30 }} />
          </button>
        </div>
      )}
    </>
  );
};

export default Alert;
