export interface IAdvertisementBlockProps {
  message: string;
  marked?: boolean;
}

export interface IAdvertisementsProps {
  adds: IAdvertisementBlockProps[];
}

export const addsMock: IAdvertisementsProps = {
  adds: [
    { message: "Ogloszenie 1" },
    { message: "Ogloszenie 2" },
    { message: "Ogloszenie 3" },
    { message: "Ogloszenie 4" },
    { message: "Ogloszenie 5" },
    { message: "Ogloszenie 6" },
    { message: "Ogloszenie 7" },
    { message: "Ogloszenie 8" },
    { message: "Ogloszenie 9" },
    { message: "Ogloszenie 10" },
  ],
};
