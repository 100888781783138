import { IAdvertisementsProps } from "../models/advertisementModel";
import style from "./Advertisements.module.scss";
import AdvertisementBlock from "./AdvertisementBlock";
import { useEffect, useState } from "react";

const MARK_DURATION = 1000;

const Advertisements = (props: IAdvertisementsProps) => {
  const [index, setIndex] = useState(0);
  const [adds, setAdds] = useState(props);

  useEffect(() => {
    const timer = setTimeout(() => {
      const updatedAdds = adds.adds.map((item, i) => {
        return { ...item, marked: i === index };
      });
      setAdds({ adds: updatedAdds });
      setIndex((prevIndex) =>
        prevIndex < adds.adds.length - 1 ? prevIndex + 1 : 0
      );
    }, MARK_DURATION);

    return () => clearTimeout(timer);
  }, [index, adds]);

  return (
    <div className={style.container}>
      {adds.adds.map((item, index) => (
        <AdvertisementBlock
          key={index}
          message={item.message}
          marked={item.marked}
        ></AdvertisementBlock>
      ))}
    </div>
  );
};

export default Advertisements;
