import React from 'react';
import style from './Header.module.scss'

const Header = () => {
    return (
        <div className={style.container}>
            <div className={style.content}>
                <p>Header</p>    
            </div>            
        </div>
    );
};

export default Header;
