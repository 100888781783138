import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { IAlert, alertsMock } from "./store.model";
import { v4 as uuidv4 } from "uuid";

export interface IStore {
  alerts: IAlert[];
  addAlert: (message: string) => void;
  removeAlert: (id: string) => void;
}

export const Store = createContext<IStore | undefined>(undefined);

interface IStoreProps {
  children: ReactNode;
}

export const StoreProvider: React.FC<IStoreProps> = ({ children }) => {
  const [alertsArray, setAlerts] = useState<IAlert[]>([]);

  useEffect(() => {}, [alertsArray]);

  const addAlert = (message: string) => {
    const newAlert: IAlert = {
      id: uuidv4(),
      message,
    };

    setAlerts((prev) => [...prev, newAlert]);
  };

  const removeAlert = (id: string) => {
    setAlerts((currentAlerts) =>
      currentAlerts.filter((alert) => alert.id !== id)
    );
  };

  const contextValue = {
    alerts: alertsArray,
    addAlert,
    removeAlert,
  };

  return <Store.Provider value={contextValue}>{children}</Store.Provider>;
};

export const useStore = (): IStore => {
  const context = useContext(Store);
  if (context === undefined) {
    throw new Error("useStore musi być używany w obrębie StoreProvider");
  }
  return context;
};
