import React, { ReactNode } from 'react';
import style from './Layout.module.scss'
import Header from 'src/modules/header/components/Header';

interface ILayoutProps {
    children: ReactNode;
}

const Layout: React.FC<ILayoutProps> = props => {
    return (
        <div className={style.container}>
            <Header/>
            <div className={style.content}>
                {props.children}
            </div>
        </div>
    );
};

export default Layout;