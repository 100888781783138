import React from "react";
import style from "./App.module.scss";
import Luncher from "./modules/luncher/Luncher";
import Advertisements from "./modules/advertisements/components/Advertisements";
import { addsMock } from "./modules/advertisements/models/advertisementModel";

function App() {
  return (
    <div className={style.App}>
      <Luncher></Luncher>
      <Advertisements {...addsMock}></Advertisements>
    </div>
  );
}

export default App;
