import React from "react";
import Button from "@mui/material/Button";
import style from "./Luncher.module.scss";
import { useStore } from "src/state/Store";

const Luncher = () => {
  const store = useStore();

  const clickHandler = () => {
    store.addAlert("Information");
  };

  return (
    <div>
      <div className={style.container}>
        <Button variant="contained" onClick={clickHandler}>
          Add Alert
        </Button>
      </div>
    </div>
  );
};

export default Luncher;
