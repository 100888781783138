import React from "react";
import style from "./AdvertisementBlock.module.scss";
import { IAdvertisementBlockProps } from "../models/advertisementModel";

const AdvertisementBlock = (props: IAdvertisementBlockProps) => {
  return (
    <div className={`${style.container} ${props.marked ? style.marked : ""}`}>
      {props.message}
    </div>
  );
};

export default AdvertisementBlock;
